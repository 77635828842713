import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Navbar from 'reactstrap/lib/Navbar';
import { useToggle } from 'site-modules/shared/hooks/use-toggle';
import { FeatureFlag } from 'site-modules/shared/components/feature-flag/feature-flag';
import { AnimatedCollapse } from 'client/site-modules/shared/utils/collapse-utils';
import { LogoSection } from './logo-section';
import { SearchBox } from './search-box';
import { NavigationLinks } from './navigation-links';
import { InsiderSection } from './insider-section';

// Navigation for xs and sm screens

const Navigations = memo(({ pathname, isAppraisalLinkExcluded }) => (
  <Navbar
    className="global-navigation-mobile-dropdown-menu px-0_25 pt-0 pb-1"
    data-tracking-parent="nav-drawer"
    aria-label="Main Navigation"
  >
    <NavigationLinks
      className="d-flex flex-column"
      renderHomeLink
      displayFullLinksText
      pathname={pathname}
      renderAppraisalLink={!isAppraisalLinkExcluded}
      isMobile
    />
    <hr className="w-100 color-gray-lighter" />
    <FeatureFlag name="insider">
      <InsiderSection className="d-flex flex-column" />
    </FeatureFlag>
  </Navbar>
));

export function GlobalNavigationMobile({
  showSearchBar,
  pathname,
  isOpen,
  toggleNav,
  preventCloseClassName,
  isAppraisalLinkExcluded,
}) {
  const [isOpenSearchDropDown, toggleSearchDropDown] = useToggle(false);

  return (
    <Fragment>
      <div
        className={classNames('global-navigation-mobile d-flex align-items-center', {
          'px-0_5': !isOpenSearchDropDown,
          'px-0_75': isOpenSearchDropDown,
          'is-open': isOpen,
        })}
      >
        {!isOpenSearchDropDown && (
          <LogoSection smallScreen toggleNav={toggleNav} isOpen={isOpen} className={preventCloseClassName} />
        )}

        {showSearchBar && (
          <SearchBox
            className="w-100"
            closeClassName="ps-1_5"
            closeStyle={{ paddingLeft: '1.5rem' }}
            onDropDownOpen={toggleSearchDropDown}
            onDropDownClose={toggleSearchDropDown}
            onCrossClick={toggleSearchDropDown}
            pathname={pathname}
            isMobile
          />
        )}
      </div>
      {isOpen && <hr className="w-100 color-gray-lighter mt-0" />}
      <AnimatedCollapse isOpen={isOpen} className={preventCloseClassName}>
        <Navigations pathname={pathname} isAppraisalLinkExcluded={isAppraisalLinkExcluded} />
      </AnimatedCollapse>
    </Fragment>
  );
}

GlobalNavigationMobile.propTypes = {
  pathname: PropTypes.string,
  showSearchBar: PropTypes.bool,
  isOpen: PropTypes.bool,
  toggleNav: PropTypes.func,
  preventCloseClassName: PropTypes.string,
  isAppraisalLinkExcluded: PropTypes.bool,
};

GlobalNavigationMobile.defaultProps = {
  pathname: '',
  showSearchBar: true,
  isOpen: false,
  toggleNav: null,
  preventCloseClassName: '',
  isAppraisalLinkExcluded: false,
};
